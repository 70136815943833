import React, { useState, useEffect } from 'react';
import './App.css';
import Typewriter from 'typewriter-effect';
import { useSpring, animated, config } from '@react-spring/web';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const BRAND_ELEMENTS = {
  name: "Zoroaster Web Design",
  tagline: "Illuminating the Future of Web Development",
  principles: [
    "Wisdom in Design",
    "Truth in Code",
    "Righteous Development"
  ],
  symbols: ["⚝", "✧", "✴", "❖"]
};

const WEBSITE_DESIGNS = [
  {
    category: "Portfolio Showcase",
    designs: [
      {
        title: "MeshGridX",
        theme: "Modern Tech",
        description: "Advanced grid computing and distributed systems platform with modern UI",
        colors: ["#0a0a0a", "#00ff88", "#ffffff"],
        features: ["Distributed Computing", "Modern Interface", "Real-time Analytics"],
        url: "https://meshgridx.com",
        preview: {
          background: "linear-gradient(120deg, #0a0a0a, #1a1a1a)",
          elements: [
            { type: "particles", count: 30 },
            { type: "text", content: "MeshGridX", style: "gradient" }
          ]
        }
      },
      {
        title: "Prometheus Lab",
        theme: "Scientific Dark",
        description: "Research and development platform focusing on cutting-edge technology",
        colors: ["#1a1a1a", "#ff3366", "#00a3ff"],
        features: ["Research Dashboard", "Data Visualization", "Interactive Models"],
        url: "https://prometheuslab.dev",
        preview: {
          background: "linear-gradient(135deg, #1a1a1a, #2d2d2d)",
          elements: [
            { type: "wave", amplitude: 20 },
            { type: "text", content: "Prometheus", style: "scientific" }
          ]
        }
      },
      {
        title: "Elegant Portfolio Pro",
        theme: "Minimalist Dark",
        description: "Sophisticated developer portfolio with AI expertise showcase and interactive project gallery",
        colors: ["#000000", "#ffffff", "#4a90e2"],
        features: ["Interactive Timeline", "Project Showcase", "Skill Matrix"],
        url: "https://paryamh.info",
        preview: {
          background: "linear-gradient(150deg, #000000, #1a1a1a)",
          elements: [
            { type: "grid", visible: true },
            { type: "text", content: "Portfolio", style: "minimal" }
          ]
        }
      },
      {
        title: "DevFolio Elite",
        theme: "Professional Dark",
        description: "Premium developer portfolio template with modern aesthetics and dynamic content presentation",
        colors: ["#121212", "#e0e0e0", "#3498db"],
        features: ["Dynamic Resume", "Project Carousel", "Tech Radar"],
        url: "https://parhammh.info",
        preview: {
          background: "linear-gradient(165deg, #121212, #1e1e1e)",
          elements: [
            { type: "particles", count: 20 },
            { type: "text", content: "DevFolio", style: "professional" }
          ]
        }
      }
    ]
  },
  {
    category: "AI & Future Tech",
    designs: [
      {
        title: "Neural Interface",
        theme: "Dark Futuristic",
        description: "A sleek, dark-themed design with neural network animations",
        colors: ["#00ff88", "#00a3ff", "#1a1a1a"],
        features: ["Particle Effects", "Neural Animations", "Glassmorphism"],
        preview: {
          background: "linear-gradient(120deg, #1a1a1a, #2a2a2a)",
          elements: [
            { type: "particles", count: 20 },
            { type: "text", content: "Neural Interface", style: "gradient" }
          ]
        }
      },
      {
        title: "Quantum Computing",
        theme: "Light Scientific",
        description: "Clean, mathematical design with quantum-inspired elements",
        colors: ["#6e00ff", "#00ffcc", "#ffffff"],
        features: ["Wave Animations", "Matrix Rain", "3D Models"],
        preview: {
          background: "linear-gradient(120deg, #ffffff, #f0f0f0)",
          elements: [
            { type: "wave", amplitude: 20 },
            { type: "text", content: "Quantum", style: "holographic" }
          ]
        }
      }
    ]
  },
  {
    category: "Creative Portfolio",
    designs: [
      {
        title: "Dynamic Canvas",
        theme: "Artistic",
        description: "Interactive canvas with generative art elements",
        colors: ["#ff3366", "#33ff99", "#3366ff"],
        features: ["Interactive Canvas", "Color Blending", "Motion Effects"],
        preview: {
          background: "linear-gradient(45deg, #ff3366, #33ff99)",
          elements: [
            { type: "canvas", interactive: true },
            { type: "text", content: "Create", style: "artistic" }
          ]
        }
      },
      {
        title: "Minimal Studio",
        theme: "Minimalist",
        description: "Clean, typography-focused design with subtle animations",
        colors: ["#000000", "#ffffff", "#ff0000"],
        features: ["Typography Focus", "Negative Space", "Micro-interactions"],
        preview: {
          background: "#ffffff",
          elements: [
            { type: "typography", style: "minimal" },
            { type: "grid", visible: false }
          ]
        }
      }
    ]
  },
  {
    category: "Immersive 3D",
    designs: [
      {
        title: "3D Experience",
        theme: "Spatial",
        description: "Full 3D environment with interactive elements",
        colors: ["#ff9900", "#9900ff", "#00ff99"],
        features: ["3D Navigation", "WebGL Effects", "Spatial Audio"],
        preview: {
          background: "linear-gradient(180deg, #000022, #220022)",
          elements: [
            { type: "3d-scene", interactive: true },
            { type: "particles", count: 100 }
          ]
        }
      }
    ]
  }
];

const AI_ADVANTAGES = [
  {
    title: "10x Faster Development",
    description: "AI-powered automation reduces development time from months to days",
    icon: "🚀",
    stats: "90% time reduction"
  },
  {
    title: "Cost Efficiency",
    description: "Create enterprise-grade websites at a fraction of traditional costs",
    icon: "💰",
    stats: "75% cost reduction"
  },
  {
    title: "24/7 Development",
    description: "AI never sleeps, continuous development and iterations",
    icon: "⚡",
    stats: "3x faster iterations"
  },
  {
    title: "Consistent Quality",
    description: "AI ensures consistent code quality and best practices",
    icon: "✨",
    stats: "99.9% code consistency"
  }
];

const CONTACT_INFO = {
  email: "parhammoe@ieee.org",
  phone: "+1 (437) 423-7704",
  location: "Toronto, Canada",
  social: {
    meshgridx: "https://meshgridx.com",
    prometheus: "https://prometheuslab.dev"
  }
};

function App() {
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState(null);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { tension: 280, friction: 60 }
  });

  const DesignPreview = ({ design }) => {
    const [isHovered, setIsHovered] = useState(false);
    const scaleProps = useSpring({
      transform: isHovered ? 'scale(1.05)' : 'scale(1)',
      config: config.wobbly
    });

    const handleClick = (e) => {
      if (design.url) {
        e.preventDefault();
        window.open(design.url, '_blank');
      } else {
        setSelectedDesign(design);
      }
    };

    return (
      <animated.div
        className="design-preview"
        style={scaleProps}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <div className="preview-header" style={{ background: design.preview.background }}>
          <h3>{design.title}</h3>
          <span className="theme-badge">{design.theme}</span>
          {design.url && <span className="visit-badge">Click to Visit ↗</span>}
        </div>
        <div className="preview-content">
          <p>{design.description}</p>
          <div className="color-palette">
            {design.colors.map((color, index) => (
              <div
                key={index}
                className="color-swatch"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
          <div className="feature-list">
            {design.features.map((feature, index) => (
              <span
                key={index}
                className="feature-tag"
                onMouseEnter={() => setHoveredFeature(feature)}
                onMouseLeave={() => setHoveredFeature(null)}
              >
                {feature}
              </span>
            ))}
          </div>
        </div>
      </animated.div>
    );
  };

  const DesignDetail = ({ design }) => {
    const detailProps = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' }
    });

    return (
      <animated.div className="design-detail" style={detailProps}>
        <button className="close-button" onClick={() => setSelectedDesign(null)}>×</button>
        <div className="detail-header" style={{ background: design.preview.background }}>
          <h2>{design.title}</h2>
          <div className="theme-info">
            <span className="theme-badge large">{design.theme}</span>
          </div>
        </div>
        <div className="detail-content">
          <div className="detail-section">
            <h3>Description</h3>
            <p>{design.description}</p>
          </div>
          <div className="detail-section">
            <h3>Color Palette</h3>
            <div className="color-palette large">
              {design.colors.map((color, index) => (
                <div key={index} className="color-detail">
                  <div className="color-swatch large" style={{ backgroundColor: color }} />
                  <span className="color-value">{color}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="detail-section">
            <h3>Features</h3>
            <div className="feature-grid">
              {design.features.map((feature, index) => (
                <div key={index} className="feature-card">
                  <span className="feature-icon">✨</span>
                  <span className="feature-name">{feature}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="button-group">
            {design.url && (
              <a 
                href={design.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="visit-site-button"
              >
                Visit Website
              </a>
            )}
            <button 
              className="preview-button"
              onClick={() => setIsPreviewMode(true)}
            >
              Launch Interactive Preview
            </button>
          </div>
        </div>
      </animated.div>
    );
  };

  const EfficiencyMetric = ({ data }) => {
    const [isHovered, setIsHovered] = useState(false);
    const scaleProps = useSpring({
      transform: isHovered ? 'scale(1.05)' : 'scale(1)',
      config: config.wobbly
    });

    return (
      <animated.div
        className="efficiency-card"
        style={scaleProps}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="efficiency-icon">{data.icon}</div>
        <h3>{data.title}</h3>
        <p>{data.description}</p>
        <div className="efficiency-stats">{data.stats}</div>
      </animated.div>
    );
  };

  const ContactSection = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      window.location.href = `mailto:${CONTACT_INFO.email}?subject=Contact from ${formData.name}&body=${formData.message}`;
    };

    return (
      <section className="contact-section">
        <div className="contact-container">
          <div className="contact-info-panel">
            <h2 className="contact-title">Get in Touch</h2>
            <p className="contact-description">
              Let's discuss your next project and bring your vision to life.
            </p>
            <div className="contact-details">
              <div className="contact-item">
                <span className="contact-icon">📧</span>
                <a href={`mailto:${CONTACT_INFO.email}`} className="contact-link">
                  {CONTACT_INFO.email}
                </a>
              </div>
              <div className="contact-item">
                <span className="contact-icon">📱</span>
                <span>{CONTACT_INFO.phone}</span>
              </div>
              <div className="contact-item">
                <span className="contact-icon">📍</span>
                <span>{CONTACT_INFO.location}</span>
              </div>
            </div>
            <div className="social-links">
              <a href={CONTACT_INFO.social.meshgridx} target="_blank" rel="noopener noreferrer" className="social-link">
                MeshGridX ↗
              </a>
              <a href={CONTACT_INFO.social.prometheus} target="_blank" rel="noopener noreferrer" className="social-link">
                Prometheus Lab ↗
              </a>
            </div>
          </div>
          <div className="contact-form-panel">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  required
                  className="form-input"
                  placeholder="Your Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  required
                  className="form-input"
                  placeholder="Your Email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={(e) => setFormData({...formData, message: e.target.value})}
                  required
                  className="form-input"
                  placeholder="Your Message"
                  rows="5"
                />
              </div>
              <button type="submit" className="submit-button">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    );
  };

  const Footer = () => (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section brand">
          <div className="footer-logo">
            <span className="sacred-symbol">⟁</span>
          </div>
          <h3>{BRAND_ELEMENTS.name}</h3>
          <p className="footer-tagline">Where Ancient Wisdom Meets Modern Technology</p>
        </div>
        
        <div className="footer-section links">
          <h4>Links</h4>
          <ul>
            <li><a href="#designs">Designs</a></li>
            <li><a href="#efficiency">AI</a></li>
          </ul>
        </div>
        
        <div className="footer-section services">
          <h4>Services</h4>
          <ul>
            <li>Web Design</li>
            <li>Development</li>
          </ul>
        </div>
        
        <div className="footer-section contact">
          <h4>Contact</h4>
          <div className="contact-info">
            <p><span className="contact-icon">📱</span> +1 (437) 423-7704</p>
            <p><span className="contact-icon">📍</span> Toronto, Canada</p>
          </div>
          <div className="social-links">
            <a href="https://meshgridx.com" className="social-icon">MeshGridX</a>
            <a href="https://prometheuslab.dev" className="social-icon">Prometheus</a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <div className="copyright">
          <p>© 2024 {BRAND_ELEMENTS.name}</p>
        </div>
      </div>
    </footer>
  );

  return (
    <div className="App">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="sacred-geometry">
          {[...Array(12)].map((_, i) => (
            <div 
              key={i} 
              className="sacred-pattern"
              style={{
                transform: `rotate(${i * 30}deg)`,
                animationDelay: `${i * 0.1}s`
              }}
            />
          ))}
        </div>

        <div className="floating-elements">
          {[...Array(20)].map((_, i) => (
            <div 
              key={i} 
              className="floating-symbol"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 80}%`,
                animationDelay: `${Math.random() * 3}s`
              }}
            >
              {BRAND_ELEMENTS.symbols[i % BRAND_ELEMENTS.symbols.length]}
            </div>
          ))}
        </div>

        <animated.div style={fadeIn} className="design-showcase">
          <header className="showcase-header">
            <div className="brand-symbol">
              <div className="sacred-symbol">Z</div>
              <div className="symbol-ring"></div>
            </div>
            <h1 className="showcase-title floating-3d">
              <span className="brand-name">{BRAND_ELEMENTS.name}</span>
              <div className="tagline">
                <Typewriter
                  options={{
                    strings: [
                      BRAND_ELEMENTS.tagline,
                      'Where Ancient Wisdom Meets Modern Tech',
                      'Crafting Digital Excellence',
                      'Enlightened Web Development'
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 80
                  }}
                />
              </div>
            </h1>
            <div className="showcase-subtitle floating-text">
              Creating exceptional websites with the wisdom of the past and the power of AI
            </div>
            <div className="efficiency-banner floating-banner">
              <span className="flame-icon">🔥</span>
              Transforming visions into digital reality
              <span className="flame-icon">🔥</span>
            </div>
          </header>

          <div className="principles-section">
            {BRAND_ELEMENTS.principles.map((principle, index) => (
              <div key={index} className="principle-card">
                <div className="principle-icon">{BRAND_ELEMENTS.symbols[index]}</div>
                <h3>{principle}</h3>
              </div>
            ))}
          </div>
        </animated.div>
      </div>

      {/* Efficiency Section */}
      <section className="efficiency-section">
        <h2 className="section-title floating-3d">Why AI-Powered Development?</h2>
        <div className="efficiency-grid">
          {AI_ADVANTAGES.map((advantage, index) => (
            <div className="efficiency-card-3d" key={index}>
              <EfficiencyMetric data={advantage} />
            </div>
          ))}
        </div>
      </section>

      {/* Design Showcase */}
      <main className="showcase-content">
        {selectedDesign ? (
          <div className="design-detail-3d">
            <DesignDetail design={selectedDesign} />
          </div>
        ) : (
          <div className="designs-container-3d">
            <Tabs>
              <TabList className="category-tabs">
                {WEBSITE_DESIGNS.map((category, index) => (
                  <Tab key={index} className="tab-3d">{category.category}</Tab>
                ))}
              </TabList>

              {WEBSITE_DESIGNS.map((category, index) => (
                <TabPanel key={index}>
                  <div className="designs-grid">
                    {category.designs.map((design, dIndex) => (
                      <div className="design-card-3d" key={dIndex}>
                        <DesignPreview design={design} />
                      </div>
                    ))}
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        )}
      </main>

      {/* Interactive Background */}
      <div className="interactive-bg">
        {[...Array(50)].map((_, i) => (
          <div 
            key={i} 
            className="particle-3d"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 80}%`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>

      {/* Contact Section */}
      <ContactSection />

      {/* Footer */}
      <Footer />

      {hoveredFeature && (
        <div className="feature-tooltip">
          {hoveredFeature}
        </div>
      )}

      {isPreviewMode && selectedDesign && (
        <div className="preview-overlay">
          <div className="preview-container">
            <button 
              className="close-preview"
              onClick={() => setIsPreviewMode(false)}
            >
              Exit Preview
            </button>
            <iframe
              title="Design Preview"
              className="design-frame"
              src={`/preview/${selectedDesign.title.toLowerCase().replace(/\s+/g, '-')}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
